<template>
  <b-row>
      <b-col lg="12" sm="12">
          <b-overlay :show="loader">
              <list-report-head :base-url="baseUrl" uri="/itf-configuration/report-head/detail" :org-id="2">
                  <template v-slot:projectNameSlot>
                  {{ }}
                  </template>
                  {{ $t('globalTrans.refund') }}
              </list-report-head>
              <b-row>
                  <b-table-simple v-if="circular_type === 1 && stalls.length" bordered small>
                      <thead>
                          <tr>
                              <b-th colspan="7" class="text-white text-center p-2 bg-dark">{{ $t('tradeFairConfig.stall_information') }}</b-th>
                          </tr>
                          <tr class="bg-primary text-center text-align-center">
                              <th style="width:10%">{{ $t('globalTrans.sl_no') }}</th>
                              <th style="width:20%">{{ $t('tradeFairConfig.stall_cat_name') }}</th>
                              <th style="width:15%">{{ $t('ditfConfig.stall_no') }}</th>
                              <th style="width:15%">{{ $t('externalTradeFair.floor_price') }}</th>
                              <th style="width:15%">{{ $t('externalTradeFair.quoted_price') }}</th>
                              <th style="width:15%">{{ $t('externalTradeFair.security_money') }}</th>
                          </tr>
                      </thead>
                      <b-tbody>
                          <span hidden>{{ $si = 1 }}</span>
                          <template v-for="(items, index) in stalls">
                              <b-tr v-for="(item, index1) in items.details" :key="index+'key'+index1">
                                  <b-td v-if="index1 === 0" class="align-middle text-center" :rowspan="items.details.length">{{ $n($si++) }}</b-td>
                                  <b-td v-if="index1 === 0" :rowspan="items.details.length" class="align-middle text-center">{{ getStallCategoryName(items.stall_cat_id) }}</b-td>
                                  <b-td class="text-center">{{ $n(item.stall_info.stall_no, {useGrouping: false}) }}</b-td>
                                  <b-td v-if="index1 === 0" :rowspan="items.details.length" class="align-middle text-center">{{ $n(getFloorPrice(items.stall_cat_id), { minimumFractionDigits: 2 }) }}</b-td>
                                  <b-td class="text-center">{{ $n(item.quoted_price, { minimumFractionDigits: 2 }) }}</b-td>
                                  <b-td v-if="index1 === 0" :rowspan="items.details.length" class="align-middle text-center">{{ $n(getSecurityMoney(items.details[0]['quoted_price']), { minimumFractionDigits: 2 }) }}</b-td>
                              </b-tr>
                          </template>
                          <b-tr>
                              <b-td colspan="5" class="text-right">{{ $t('globalTrans.total') }}</b-td>
                              <b-td class="text-center">{{ $n(getTotalSMoney(stalls), { minimumFractionDigits: 2 }) }}</b-td>
                          </b-tr>
                          <b-tr>
                              <b-td colspan="3" class="text-right">{{ $t('externalTradeFair.refunded_amount') }}</b-td>
                              <b-td class="text-center">{{ $n(app_info.app_refund.total_refund, { minimumFractionDigits: 2 }) }}</b-td>
                          </b-tr>
                      </b-tbody>
                  </b-table-simple>
                  <b-table-simple v-if="circular_type === 2" bordered small>
                      <thead>
                          <tr>
                              <b-th colspan="5" class="text-white text-center p-2 bg-dark">{{ $t('tradeFairConfig.stall_information') }}</b-th>
                          </tr>
                          <tr class="bg-primary text-center text-align-center">
                              <th style="width:10%">{{ $t('globalTrans.sl_no') }}</th>
                              <th style="width:20%">{{ $t('tradeFairConfig.stall_cat_name') }}</th>
                              <th style="width:15%">{{ $t('externalTradeFair.floor_price') }}</th>
                              <th style="width:15%">{{ $t('externalTradeFair.quoted_price') }}</th>
                          </tr>
                      </thead>
                      <b-tbody>
                          <span hidden>{{ $si = 1 }}</span>
                          <b-tr v-for="(item, index2) in app_info.stall_other" :key="index2">
                              <b-td class="align-middle text-center">{{ $n($si++) }}</b-td>
                              <b-td class="align-middle text-center">{{ getStallCategoryName(item.stall_cat_id) }}</b-td>
                              <b-td class="align-middle text-center">{{ $n(getFloorPrice(item.stall_cat_id)) }}</b-td>
                              <b-td class="text-center">{{ $n(item.quoted_price) }}</b-td>
                          </b-tr>
                          <b-tr>
                              <b-td colspan="3" class="text-right">{{ $t('globalTrans.total') }}</b-td>
                              <b-td class="text-center">{{ $n(getQuotedPrice(app_info.stall_other)) }}</b-td>
                          </b-tr>
                          <b-tr>
                              <b-td colspan="3" class="text-right">{{ $t('externalTradeFair.refunded_amount') }}</b-td>
                              <b-td class="text-center">{{ $n(app_info.app_refund.total_refund, { minimumFractionDigits: 2 }) }}</b-td>
                          </b-tr>
                      </b-tbody>
                  </b-table-simple>
              </b-row>
              <b-row class="mb-3 mx-2 mt-2">
                  <b-col md="3" style="font-weight:bold">{{$t('externalTradeFair.refunded_date')}} {{":"}}</b-col>
                  <b-col md="3" class="p-0 text-left">{{ app_info.app_refund.refund_date }}</b-col>
                  <b-col md="3" style="font-weight:bold">{{$t('globalTrans.comments')}} {{":"}}</b-col>
                  <b-col md="3">{{ app_info.app_refund.comments }}</b-col>
              </b-row>
          </b-overlay>
      </b-col>
  </b-row>
</template>
<script>
import { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHead.vue'

export default {
  name: 'Form',
  props: ['id', 'circular_type'],
  components: {
      ListReportHead
  },
  data () {
      return {
          baseUrl: internationalTradeFairServiceBaseUrl,
          loader: false,
          app_info: {},
          stalls: []
      }
  },
  created () {
      if (this.id) {
          this.app_info = this.getRefundInfo()
          if (this.app_info.circular_type === 1) {
              this.mappingStallInfo(this.app_info.stall_pavilion)
          }
      }
  },
  methods: {
      mappingStallInfo (data) {
            const details = []
            const booked = data.find(el => el.status === 2)
            let stallArr = null
            if (typeof booked !== 'undefined') {
                stallArr = data.filter(mItem => mItem.stall_cat_id !== booked.stall_cat_id && mItem.status === 1)
            } else {
                stallArr = data.filter(mItem => mItem.status === 1)
            }
            stallArr.forEach(item => {
                const Obj = details.find(el => el.stall_cat_id === item.stall_cat_id)
                if (typeof Obj === 'undefined') {
                    details.push({ stall_cat_id: item.stall_cat_id })
                }
            })
            const tA = details.map(el => {
                const newArry = data.filter(item => item.stall_cat_id === el.stall_cat_id)
                return Object.assign({}, el, { details: newArry })
            })
            this.stalls = tA
      },
      getFloorPrice (id) {
          const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.stallCategoryList.find(item => parseInt(item.value) === parseInt(id))
          return Obj !== undefined ? Obj.floor_price : ''
      },
      getStallCategoryName (id) {
          const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.stallCategoryList.find(item => parseInt(item.value) === parseInt(id))
          if (this.$i18n.locale === 'bn') {
          return Obj !== undefined ? Obj.text_bn : ''
          } else {
          return Obj !== undefined ? Obj.text_en : ''
          }
      },
      getSecurityMoney (data) {
          const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.securityMoneyList.find(item => item.year === this.app_info.year)
          const percentage = Obj !== undefined ? Obj.percentage : 0
          return (percentage * data) / 100
      },
      getQuotedPrice (items) {
          if (items) {
              let quotedPrice = 0
              items.filter(el => el.status === 1).map((item) => {
                  quotedPrice += parseInt(item.quoted_price)
              })
              return quotedPrice
          }
      },
      getTotalSMoney (items) {
          if (items) {
              let sMoney = 0
              const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.securityMoneyList.find(item => item.year === this.app_info.year)
              const percentage = Obj !== undefined ? Obj.percentage : 0
              items.map(el => {
                  sMoney += (el.details[0].quoted_price * percentage) / 100
              })
              return sMoney
          }
      },
      getRefundInfo () {
          const tmpData = this.$store.state.list.find(item => item.id === this.id)
          return JSON.parse(JSON.stringify(tmpData))
      }
  }
}
</script>
